export const sendToMessageWhatsApp = (numberPhone?: string, message?: string) => {
  const baseWhatsappUrl = 'https://api.whatsapp.com/send';
  if (numberPhone && message) {
    return `${baseWhatsappUrl}?phone=55${numberPhone}&text=${message}`;
  } else if (numberPhone && !message) {
    return `${baseWhatsappUrl}?phone=55${numberPhone}`;
  } else if (!numberPhone && message) {
    return `${baseWhatsappUrl}?text=${message}`;
  }
  return `${baseWhatsappUrl}`;
};
