import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../../components/Icons';
import { cellPhone } from '../../../../utils/mask';
import { ItemProps } from '../interfaces';
import styles from './style.module.css';

export const ProposalShow = ({ data, handleDelete }: ItemProps) => {
  const { email, name, time, phone, carName, proposal, id } = data;
  const contentClass = cx('ph16 ph32-ns pb24 relative mb24', styles.contentClass);
  const proposalTextClass = cx('ma0 f14 fw3 tj overflow-auto', styles.proposalTextClass);
  return (
    <section className="pt48 pt0-ns abbey">
      <h2 className="ma0 fw2 f26 pt24 pt32-ns pl16 pl32-ns pb32">
        Proposta recebida<p className="ma0 fw7 red pr32-ns">{name}</p>
      </h2>
      <div className={contentClass}>
        <p className="ma0 f12 fw3 boulder mb8">{time}</p>
        <p className="ma0 f18 fw7 mb16 mb24-ns">{carName}</p>
        <p className={proposalTextClass}>{proposal}</p>
      </div>
      <div className="ph16 ph32-ns pb24 pb32-ns relative red flex-ns justify-between items-center">
        <a
          className="flex items-center mb24 mb0-ns w100 w-40-ns pointer red no-underline"
          href={`mailto:${email}`}
          title={`Enviar email para ${email}`}
        >
          <div>
            <Icon
              name="SvgIconMail"
              className="v-mid"
              height={24}
              width={24}
              customId="proposal-show"
            />
          </div>
          <span className="f14 fw4 pl4 truncate" title={email}>
            {email}
          </span>
        </a>
        <a
          className="flex items-center mb32 mb0-ns pointer red no-underline"
          href={`tel:${phone}`}
          title={`Ligar para ${phone}`}
        >
          <div>
            <Icon
              name="SvgIconPhone"
              className="v-mid"
              height={22}
              width={22}
              customId="proposal-show"
            />
          </div>
          <span className="f14 fw4 pl4">{cellPhone(phone)}</span>
        </a>
        <button
          data-id={id}
          className="fw4 f14 abbey underline pointer bn pa0 bg-transparent"
          onClick={handleDelete}
        >
          <span className="db dn-ns">Excluir proposta</span>
          <Icon name="SvgIconDelete" className="dn db-ns" />
        </button>
      </div>
    </section>
  );
};
