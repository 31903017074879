import React from 'react';
import map from 'lodash/map';
import { Icon } from '../../../../components/Icons';
import { ProposalListSmallProps } from '../interfaces';
import { SmallerButton } from './SmallerButton';

export const ProposalListSmall = ({ data, onClick }: ProposalListSmallProps) => {
  const names = map(data, ({ name }, index) => {
    const firstName = name.split(' ')[0];
    const separator = index !== data.length - 1 ? ', ' : '.';
    return firstName + separator;
  });
  const smallButtonText = `${names.length} novas`;

  const treatEmpty = () => {
    if (data.length > 0) {
      return <h3 className="ma0 fw6 f14 pb4">Novas Propostas</h3>;
    }
    return (
      <>
        <h3 className="ma0 fw6 f14 pb4 w-100">Não há propostas</h3>
        <h3 className="ma0 fw2 f14 pb4 w-100">no momento</h3>
      </>
    );
  };

  return (
    <section
      className="pt32 ph16 pv24 abbey flex items-center pointer bg-white relative w-100"
      onClick={onClick}
    >
      <div>
        <Icon name="SvgIconMail" className="red" customId="proposal-list-small" />
      </div>
      <div className="pl8 w-100">
        {treatEmpty()}
        <p className="ma0 fw3 f14 lh-copy truncate w-70">{names}</p>
      </div>
      <div className="absolute flex items-center h-100 right-0 pr16">
        <SmallerButton>{smallButtonText}</SmallerButton>
      </div>
    </section>
  );
};
