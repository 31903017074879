import { axiosInstance, axiosInstanceWithAuth } from '../../../services/axios-instance';

export const AdvertisementService = {
  getAdvertisement: async (queryParam: string) => {
    return await axiosInstance
      .get(`/advertisement/${queryParam}`)
      .then(response => response)
      .catch(error => error.response);
  },
  getProductAdvertisement: async (queryParam: string) =>
    await axiosInstance
      .get(`advertisement/partServices/${queryParam}`)
      .then(response => response)
      .catch(error => error.response),
  generatePaymentCode: async ({ productId, amount }: { productId: number; amount: number }) =>
    await axiosInstanceWithAuth
      .post('/orders', { adPartsServicesId: productId, amount })
      .catch(err => err),
};

export const RegisterViewsServices = {
  postCountViews: async (advertisementId: number) => {
    return await axiosInstance
      .post(
        '/advertisement/view',
        { advertisementId },
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(resp => resp)
      .catch(err => err);
  },
  whatsAppCountViews: async (advertisementId: number) => {
    return await axiosInstance
      .post(
        '/advertisementContacts',
        { advertisementId },
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(resp => resp)
      .catch(err => err);
  },
};
