import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './style.module.css';

interface InfoProps {
  color: string;
  backgroundColor: string;
  icon: string;
  title: string;
  subtitle: string;
}

interface Props {
  info: InfoProps;
}

export const AdInfoItem = ({ info }: Props) => {
  const { color, backgroundColor, icon, title, subtitle } = info;

  const circleClass = cx(
    'br-pill self-center mr8 flex items-center justify-center',
    styles.roundedDiv,
  );

  const mainClass = cx(
    'flex br10 bg-white pv12 ph8 ml24 ml0-ns ml24-m ml24-l flex-grow-0-ns flex-auto-m flex-auto-l',
    styles.mainDiv,
  );

  return (
    <div className={mainClass}>
      <div className={circleClass} style={{ background: backgroundColor }}>
        <Icon name={icon} width={16} height={16} className={color} />
      </div>
      <div className="flex flex-column justify-center lh-title">
        <div className="f24 fw7 abbey"> {title} </div>
        <div className="f14 fw3 boulder nowrap"> {subtitle} </div>
      </div>
    </div>
  );
};
