import React, { useEffect, useState } from 'react';
import { useListener, usePrevious } from '../../../../utils/hooks';
import { ContainerHeader } from '../HeaderMenu/ContainerHeader';
import { TopContent } from './TopContent';

interface Props {
  handleShare?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  dataValue?: string | any;
}
export const MenuAdTop = ({ handleShare, dataValue }: Props) => {
  const [visible, setVisible] = useState(true);
  const [isMenuOnTop, setIsMenuOnTop] = useState(true);
  const [scrollPos, setScrollPos] = useState<number>(0);
  const prevScrollpos = usePrevious(scrollPos);
  if (typeof window !== 'undefined') {
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };
    useListener(window, 'scroll', handleScroll);
  }
  useEffect(() => {
    if (scrollPos <= 72) {
      setIsMenuOnTop(true);
    } else {
      setIsMenuOnTop(false);
    }
    if ((prevScrollpos as unknown as number) > scrollPos) {
      setVisible(true);
    }
    if (scrollPos > 72 && (prevScrollpos as unknown as number) <= scrollPos) {
      setVisible(false);
    }
  }, [scrollPos]);
  return (
    <ContainerHeader isVisible={visible} isMenuOnTop={isMenuOnTop}>
      <TopContent handleShare={handleShare} dataValue={dataValue} />
    </ContainerHeader>
  );
};
