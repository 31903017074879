import React from 'react';
import { ContainerMenuMobile } from '../MenuMobile/ContainerMenuMobile';
import { BottomContent } from './BottomContent';

interface Props {
  alwaysVisible?: boolean;
  name: string;
  price: string;
  cellPhone: string;
  isWhattsApp?: boolean;
  dataValue?: string;
  adId: number;
}
export const MenuAdBottom = ({
  alwaysVisible,
  name,
  price,
  cellPhone,
  isWhattsApp,
  dataValue,
  adId,
}: Props) => {
  return (
    <ContainerMenuMobile alwaysVisible={alwaysVisible}>
      <BottomContent
        name={name}
        price={price}
        cellPhone={cellPhone}
        isWhattsApp={isWhattsApp}
        dataValue={dataValue}
        adId={adId}
      />
    </ContainerMenuMobile>
  );
};
