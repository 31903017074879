import React from 'react';
import cx from 'classnames';
import { Container } from '../../../../components/Container';
import { Icon } from '../../../../components/Icons';
import { isStore } from '../../../../utils/functions';
import styles from './style.module.css';

interface Props {
  handleShare?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dataValue?: string;
}

export const TopContent = ({ handleShare, dataValue }: Props) => {
  const gtmClass = isStore() ? 'gtmShareStore' : 'gtmShareAd';
  // const [like, setLike] = useState(false);
  const mainContentClass = cx('w-100 bg-white ph8', styles.mainContentClass);
  // const hearthIcon = like ? 'SvgIconHeartfull' : 'SvgIconHeart';
  // const clickLike = () => {
  //   setLike(!like);
  // };
  const buttonWithIconClass = cx('bn bg-transparent pointer', styles.clickArea);
  const shareLikeClass = cx({ dn: !handleShare });
  // const hearthAriaLabel = like ? 'Adicionado aos favoritos' : 'Adicionar aos favoritos';
  const goBack = () => history.back();
  return (
    <div className={mainContentClass}>
      <Container>
        <div className="flex justify-between">
          <button
            className={buttonWithIconClass}
            onClick={goBack}
            title="Voltar"
            aria-label="Voltar"
          >
            <Icon name="SvgIconBack" />
          </button>
          <div className={shareLikeClass}>
            {
              // tslint:disable-next-line: jsx-no-multiline-js
              /*             <button
              className={buttonWithIconClass}
              onClick={clickLike}
              title={hearthAriaLabel}
              aria-label={hearthAriaLabel}
            >
              <Icon name={hearthIcon} />
            </button> */
            }
            <button
              className={cx(buttonWithIconClass, gtmClass)}
              onClick={handleShare}
              title="Compartilhar"
              aria-label="Compartilhar"
              data-value={dataValue}
            >
              <Icon
                className={gtmClass}
                name="SvgIconShare"
                customId="top-content"
                dataValue={dataValue}
              />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};
