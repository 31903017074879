import React from 'react';
import cx from 'classnames';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { Button } from '../../../../components/Button';
import { GridCell } from '../../../../components/GridCell';
import { Icon } from '../../../../components/Icons';
import { sendToMessageWhatsApp } from '../../../../utils/callToWhatsApp';
import { formatCurrency } from '../../../../utils/formatNumber';
import { ellipsis } from '../../../../utils/functions';
import { RegisterViewsServices } from '../../../Advertisement/services/AdvertisementService';
import styles from './style.module.css';

interface Props {
  price: string;
  name: string;
  cellPhone: string;
  isWhattsApp?: boolean;
  dataValue?: string;
  adId: number;
}
export const BottomContent = ({ price, name, cellPhone, isWhattsApp, dataValue, adId }: Props) => {
  const formattedPrice = formatCurrency(price);
  const mainContentClass = cx('flex justify-between w-100 bg-white pv4', styles.negativeMargin);
  const wattsMessage = `Olá, vi um anúncio no site Autoscar.com.br e gostaria de mais informações! ${window.location.href}`;

  const redirectFor = isWhattsApp
    ? sendToMessageWhatsApp(cellPhone, wattsMessage)
    : `tel:${cellPhone}`;

  const handleOnClick = async () => {
    await RegisterViewsServices.whatsAppCountViews(adId);
    trackCustomEvent({
      action: 'clique',
      category: 'botao',
      label: 'whatsapp',
    });
    window.location.href = redirectFor;
  };
  return (
    <div className={mainContentClass}>
      <GridCell width={[5 / 12, 2 / 12]} className="flex justify-center flex-column">
        <p className="ma0 fw7 f13 lh-copy abbey" title={price}>
          {ellipsis(formattedPrice, 15)}
        </p>
        <p className="ma0 fw4 f11 lh-copy boulder" title={name}>
          {ellipsis(name, 15)}
        </p>
      </GridCell>
      <GridCell width={[7 / 12, 4 / 12]}>
        <Button
          classButton="ph12 f18 fw7 ma0 pv0 lh-solid flex items-center tl gtmSendMessageWppAd"
          green
          resetClass
          onClick={handleOnClick}
          data-value={dataValue}
        >
          <Icon
            className="db gtmSendMessageWppAd"
            name="SvgIconShowWhatsapp"
            width={24}
            height={24}
            dataValue={dataValue}
          />
          <span className="pl4 gtmSendMessageWppAd">
            {isWhattsApp ? 'Enviar mensagem' : 'Ligar agora'}
          </span>
        </Button>
      </GridCell>
    </div>
  );
};
