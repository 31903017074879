import React from 'react';
import find from 'lodash/find';
import { StyledModal } from '../../../components/StyledModal';
import { ProposalList } from './components/ProposalList';
import { ProposalShow } from './components/ProposalShow';
import { ModalProps } from './interfaces';
import styles from './style.module.css';

export const ModalShowProposal = ({
  open,
  proposalControl,
  isMobile = false,
  data,
  handleDelete,
}: ModalProps) => {
  const [isOpen, setIsOpen] = open;
  const [proposalId, setProposalId] = proposalControl;

  const close = () => {
    if ((isMobile && proposalId === -1) || !isMobile) {
      setIsOpen(!isOpen);
    }

    if (isMobile && proposalId !== -1) {
      setProposalId(-1);
    }
  };
  const showProposalOrList = () => {
    const dataToShow = find(data, ['id', proposalId]);
    if (isMobile && proposalId === -1) {
      return (
        <div className="pt32">
          <ProposalList data={data} setCurrent={setProposalId} handleDelete={handleDelete} />
        </div>
      );
    }
    if (dataToShow) {
      return <ProposalShow data={dataToShow} handleDelete={handleDelete} />;
    }
    return null;
  };
  return (
    <StyledModal
      isOpen={isOpen}
      className={styles.modalClass}
      backButton={proposalId !== -1 && isMobile}
      closeModal={close}
    >
      {showProposalOrList()}
    </StyledModal>
  );
};
