import React from 'react';
import cx from 'classnames';
import { SmallerButtonProps } from '../interfaces';
import styles from './style.module.css';

export const SmallerButton = ({ children }: SmallerButtonProps) => {
  const smallerBUttonClass = cx(
    'pa0 ma0 f11 fw6 bn white pointer br1 ph8 nowrap',
    styles.smallerButtonClass,
  );
  return <button className={smallerBUttonClass}>{children}</button>;
};
