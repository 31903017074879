import React, { useState } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import MediaQuery from 'react-responsive';
import { OutlineButton, Button } from '../../../../components/Button';
import { ButtonIcon } from '../../../../components/Button';
import { Icon } from '../../../../components/Icons';
import { LinkGray } from '../../../../components/Link';
import { ModalSuccessAndError } from '../../../../components/ModalSuccessAndError';
import { DeleteAdServices } from '../../../AdList/services/AdServices';
import { formatUrlToEditPage } from '../../../Home/utils/formattedUrl';
import { ACTIVE } from '../../utils/functions';
import { EditAdDataType } from '../../utils/Type';
import styles from './styles.module.css';

export const HeaderEdit = ({
  data,
  backRedirect,
}: {
  data: EditAdDataType;
  backRedirect?: string;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const controlModal = () => setOpenModal(!openModal);
  const { Parts } = data;
  const isParts = Boolean(Parts.name);
  const onClickDelete = () => {
    if (isParts) {
      DeleteAdServices.deletePardAd(data.id);
    } else {
      DeleteAdServices.deleteAd(data.id);
    }
    return navigate(backRedirect || 'meus-anuncios');
  };
  const goToAdPage = () => navigate(formatUrlToEditPage(data, isParts));

  const { date, status } = data;
  const title = data.title === ' ' ? data.Parts.name : data.title;
  const buttonIconClass = cx('ttu fw7 ml12 ml24-ns white', styles.btnViewPage);
  const headerIconClass = cx('red mt8', styles.headerIconClass);

  const getAdPage = () => {
    return (
      <>
        <MediaQuery maxWidth={767}>
          <Button
            onClick={goToAdPage}
            classButton={buttonIconClass}
            size="medium"
            resetClass
          >
            Ver página
          </Button>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <ButtonIcon
            onClick={goToAdPage}
            iconLeft="SvgIconShowMe"
            classButton={buttonIconClass}
            size="medium"
          >
            Ver página
          </ButtonIcon>
        </MediaQuery>
      </>
    );
  };

  return (
    <>
      <div className="mb32 mb24-ns">
        <LinkGray className="dn db-m db-l mb16 underline" to={backRedirect}>
          Voltar para anúncios
        </LinkGray>
        <div className="flex-ns justify-between items-start pl16 pl0-ns">
          <div className="mb24 mb0-ns flex items-start">
            <Icon name="SvgIconEdit" className={headerIconClass} />
            <div className="ml8">
              <h1 className="ma0 mb4 abbey f20 f30-m f30-l b ttu lh-copy">{title}</h1>
              <p className="ma0 abbey f12 f12-m f12-l">Data de criação: {date}</p>
            </div>
          </div>
          <div className="flex-ns items-center justify-between">
            <div className="flex">
              <OutlineButton
                resetPadding
                className={cx('flex items-center', styles.btnDelete)}
                size="medium"
                onClick={controlModal}
              >
                <Icon name="SvgIconDelete" className="mr4" height={17} width={17} />
                Excluir
              </OutlineButton>
              {Number(status) === ACTIVE && getAdPage()}
            </div>
          </div>
        </div>
      </div>
      <ModalSuccessAndError
        title="Atenção"
        subTitle="Deseja realmente excluir este anúncio?"
        isOpen={openModal}
        success={false}
        actionBtn={onClickDelete}
        closeModal={controlModal}
        actionBtnSecondary={controlModal}
        textBtnClose="Excluir"
        textBtnSecondary="Cancelar"
      />
    </>
  );
};
