import React from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { Icon } from '../../../../components/Icons';
import { ListProps } from '../interfaces';
import { ProposalItem } from './ProposalItem';
import styles from './style.module.css';

export const ProposalList = ({ data, setCurrent, handleDelete }: ListProps) => {
  const mapProposals = () =>
    map(data, proposal => {
      const { id } = proposal;
      const handlePreview = () => {
        setCurrent(id);
      };

      return (
        <ProposalItem
          data={proposal}
          handleDelete={handleDelete}
          handlePreview={handlePreview}
          key={'proposalItem' + id}
        />
      );
    });
  const sectionClass = cx(
    'abbey bg-white h-100 br10-ns pt16 overflow-auto',
    {
      pb24: data.length > 0,
      'pb12 pb24-m pb24-l': !data || data.length === 0,
    },
    styles.proposalListClass,
  );

  const getContent = () => {
    if (!data || data.length === 0) {
      return (
        <>
          <h2 className="ma0 fw2 f26 pl24 pb8 pb24-m pb24-l">
            <p className="ma0 fw7 red f24">Não há propostas</p>
            <p className="ma0"> no momento. </p>
          </h2>
          <div className="w-100 flex justify-center">
            <Icon name="SvgIconEmptyProposal" />
          </div>
        </>
      );
    }
    return (
      <>
        <h2 className="ma0 fw2 f26 pl24 pb24">
          Novas <p className="ma0 fw7 red f24">Propostas</p>
        </h2>
        <div className="overflow-x-hidden h-auto pb16 pt16">{mapProposals()}</div>
      </>
    );
  };

  return <section className={sectionClass}>{getContent()}</section>;
};
