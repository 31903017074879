import React from 'react';
import { Layout } from '../components/Layout';
import { PrivateRoute } from '../components/PrivateRoute';
import { Footer } from '../modules/Footer';
import { Menu } from '../modules/Menu';
import { Top } from '../modules/Menu/components/MenuAd';
import { VehicleRegistration } from '../modules/VehicleRegistration/editVehicle';

const EditWrapper = () => (
  <div className="bg-wild-sand">
    <Layout>
      <div className="db db-ns dn-m dn-l">
        <Top />
        <Menu menuHasTwoColors={false} top={false} />
      </div>
      <div className="dn db-m db-l">
        <Menu menuHasTwoColors={false} />
      </div>
      <VehicleRegistration />
      <Footer />
    </Layout>
  </div>
);

const editAdPage = ({ ...props }) => {
  return <PrivateRoute location={props.location.pathname} component={EditWrapper} />;
};

export default editAdPage;
