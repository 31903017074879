import React, { useState } from 'react';
import { clone } from 'lodash';
import { observer, inject } from 'mobx-react';
import { ModalSuccessAndError } from '../../components/ModalSuccessAndError';
import { convertAd, convertAdParts } from '../../utils/treatData';
import { advertisement } from './services/advertisement';
import { StepFour } from './StepFour';
import { StepOne } from './StepOne';
import { StepParts } from './StepParts';
import { StepThree } from './StepThree';
import { StepTwo } from './StepTwo';
import { stepKeys } from './utils/functions';
import { FormEditAdProps, Values } from './utils/Type';

export const FormEditAd = inject(
  'registration',
  'user',
)(
  observer(
    ({
      changeOverlay,
      overlay,
      data,
      urlId,
      registration,
      updateData,
      isParts,
      user,
    }: FormEditAdProps) => {
      const modalSucess = {
        title: 'Parabéns!',
        subTitle: 'Suas alterações foram salvas com sucesso.',
        success: true,
      };
      const modalError = {
        title: 'Ops!',
        subTitle: 'Ocorreu um erro ao completar sua ação.',
        success: false,
      };
      const [values, setValues] = useState(data);
      const [openModal, setOpenModal] = useState(false);
      const [sucess, setSucess] = useState(true);
      const [isLoading, setIsLoading] = useState(false);
      const controlModal = () => setOpenModal(!openModal);
      const clickModal = () => {
        controlModal();
        changeOverlay(0);
      };
      const nextEditStep = async (step: number, payload?: any, typeParts?: boolean) => {
        setIsLoading(true);
        if (payload && typeParts) {
          const clonedPayload = clone(payload);
          const newPayload = convertAdParts({ valuesParts: clonedPayload, urlId });
          await advertisement
            .updateParts(newPayload)
            .then((res: any) => {
              if (res.status >= 200 && res.status < 300) {
                setSucess(true);
                updateData(urlId);
                setIsLoading(false);
              }
            })
            .catch((res: any) => {
              setSucess(false);
              console.error(res);
              setIsLoading(false);
            });
          setOpenModal(true);
          setValues(clonedPayload);
        }

        if (payload && !typeParts) {
          console.log(payload);
          const key: keyof Values = stepKeys[step - 1];
          values[key] = payload;
          setSucess(false);
          await registration
            .registerVehicle(convertAd(values, parseInt(data.status, 10)), urlId)
            .then((res: any) => {
              if (res.status >= 200 && res.status < 300) {
                setSucess(true);
                updateData(urlId);
                setIsLoading(false);
              }
            })
            .catch((res: any) => {
              console.error(res);
              setIsLoading(false);
            });
          setOpenModal(true);
          setValues(values);
        }
      };
      const { photos } = data.Gallery;
      const { Ad, Optionals, Car, Parts } = data;
      const modalConfig = sucess ? modalSucess : modalError;
      const { vehicleType } = Car;
      const stepConfig = {
        edit: true,
        isLoading,
        overlay,
        changeOverlay,
        nextStep: nextEditStep,
        vehicleType: Number(vehicleType),
        updateData,
      };
      const renderSteps = () =>
        isParts ? (
          <StepParts {...stepConfig} defaultValues={Parts} updateValues={updateData} />
        ) : (
          <div className="w-100 bg-wild-sand pb24">
            <StepThree
              values={Ad}
              {...stepConfig}
              isLoading={isLoading}
              isAdmin={user.profile.admin}
            />
            <StepFour gallery={photos} {...stepConfig} isLoading={isLoading} />
            <StepTwo defaultValues={Optionals} {...stepConfig} isLoading={isLoading} />
            <StepOne defaultValues={Car} urlId={urlId} {...stepConfig} isLoading={isLoading} />
          </div>
        );
      return (
        <>
          {renderSteps()}
          <ModalSuccessAndError
            {...modalConfig}
            isOpen={openModal}
            actionBtn={clickModal}
            closeModal={controlModal}
            actionBtnSecondary={controlModal}
            textBtnClose="OK"
          />
        </>
      );
    },
  ),
);
