import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../../components/Icons';
import { ItemProps } from '../interfaces';
import styles from './style.module.css';

export const ProposalItem = ({ data, handleDelete, handlePreview }: ItemProps) => {
  const { id, name, time, proposal } = data;
  const containerClass = cx('pv16 ph24 pointer relative br3', styles.proposalItem);
  const divOnHover = cx(
    'absolute right-0 top-0 justify-center items-center red bn pointer bg-white dn flex-m flex-l',
    styles.divOnHover,
  );

  const gradientCx = cx('flex flex-column relative', styles.gradientOnHover);

  return (
    <div className={containerClass}>
      <div className={gradientCx} data-id={id} onClick={handlePreview}>
        <div className="flex justify-between">
          <p className="ma0 pb4 fw7 f14 truncate w-60">{name}</p>
          <p className="ma0 pb4 fw3 f12">{time}</p>
        </div>
        <p className="ma0 fw3 f14 lh-copy truncate w-100">{proposal}</p>
      </div>
      <div className={divOnHover}>
        <button
          type="button"
          data-id={id}
          onClick={handleDelete}
          className="red bn pointer bg-white justify-center items-center mr40"
        >
          <Icon name="SvgIconDelete" key={'deleteIconProposal' + id} />
        </button>
        <button
          type="button"
          data-id={id}
          onClick={handlePreview}
          className="absolute red bn pointer bg-white justify-center items-center ml24"
        >
          <Icon name="SvgIconView" key={'viewIconProposal' + id} />
        </button>
      </div>
    </div>
  );
};
