import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { AdInfo } from '../../components/AdInfo';
import { Grid } from '../../components/Grid';
import { GridCell } from '../../components/GridCell';
import { LoaderFullPage } from '../../components/Loaders';
import { ModalSuccessAndError } from '../../components/ModalSuccessAndError';
import { Overlay } from '../../components/Overlay';
import { convertStringToNumber } from '../../utils/formatNumber';
import { PARTS_NUMBER } from '../../utils/productType';
import { HeaderEdit } from './components/HeaderEdit';
import { Proposals } from './components/Proposals';
import { FormEditAd } from './formEditAd';
import { advertisement } from './services/advertisement';
import styles from './styles.module.css';
import { editAdEmpty } from './utils/Emptys';
import { formatProposals, formatAd, vehicleTypeNameByNumber } from './utils/functions';

export const VehicleRegistration = () => {
  const [urlId, setUrlId] = useState<string>('');
  const [overlay, setOverlay] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(editAdEmpty);
  const [proposals, setProposals] = useState<any[]>([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentIdProposal, setCurrentIdProposal] = useState<number>();
  const [isParts, setIsParts] = useState(false);

  const isPartsAd =
    typeof window !== 'undefined' && window.location.pathname.split('/')[2] === 'pecas';

  const getData = async (id?: string) => {
    if (id && id !== 'editar-anuncio') {
      setUrlId(id);
      return isPartsAd
        ? advertisement
            .getAdParts(id)
            .then(res => {
              setData(formatAd(res.data));
            })
            .catch(() => navigate('/meus-anuncios/pecas'))
        : advertisement
            .getAd(id)
            .then(res => setData(formatAd(res.data)))
            .catch(() => navigate('/meus-anuncios'));
    }
    navigate('/meus-anuncios/pecas');
  };

  useEffect(() => {
    const query = typeof window !== 'undefined' ? last(window.location.pathname.split('/')) : '';
    getData(query);
    setIsParts(isPartsAd);
  }, []);

  useEffect(() => {
    if (!isEqual(data, editAdEmpty)) {
      if (!isPartsAd) {
        advertisement
          .getProposals(urlId)
          .then(res => {
            setProposals(formatProposals(res.data, data.title));
            setLoading(false);
          })
          .catch(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  }, [data]);

  const closeModal = () => setOpenModalConfirm(!openModalConfirm);

  const changeOverlay = (stepId: number) => setOverlay(stepId);

  const getAfterDeleteProposal = async () => {
    if (!isEqual(data, editAdEmpty)) {
      if (!isPartsAd) {
        await advertisement
          .getProposals(urlId)
          .then(res => {
            setProposals(formatProposals(res.data, data.title));
            setOpenModal(false);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    setOpenModalConfirm(!openModalConfirm);
    const currentProposalId = convertStringToNumber(e.currentTarget.dataset.id);
    setCurrentIdProposal(currentProposalId);
  };

  const confirmOnDelete = async () => {
    setLoading(true);
    await advertisement
      .deleteProposal(currentIdProposal)
      .then(() => {
        getAfterDeleteProposal();
      })
      .catch(() => setLoading(false));
  };

  const getBackRedirect = () => {
    const { Parts, Car } = data;
    const endPath = vehicleTypeNameByNumber(Parts.name ? PARTS_NUMBER : Car.vehicleType);

    return `/meus-anuncios/${endPath}`;
  };

  const containerStyleSmall = cx(styles.container, 'pt72 pt24-m pt24-l mt16');
  if (loading) {
    return <LoaderFullPage />;
  }
  const renderProposals = () => {
    return isParts ? null : (
      <>
        <GridCell
          width={[1, 5 / 12, 1]}
          className={cx('mb24 pr12-ns pl16 pl0-ns h-100-ns', styles.containerProposal)}
        >
          <AdInfo data={data} />
        </GridCell>
        <GridCell
          width={[1, 7 / 12, 3 / 10]}
          className={cx('mb24 overflow-hidden h-100-ns pl24-m pl24-l', styles.containerProposal)}
        >
          <Proposals
            data={proposals}
            handleDelete={handleDelete}
            toggleModal={[openModal, setOpenModal]}
          />
        </GridCell>
      </>
    );
  };
  const gridWidth = isParts ? [0.9] : [1, 1, 7 / 10];
  return (
    <>
      <Overlay overlay={overlay} setOverlay={changeOverlay} />
      <div className={containerStyleSmall}>
        <HeaderEdit data={data} backRedirect={getBackRedirect()} />
        <Grid
          width={1}
          // tslint:disable-next-line: jsx-no-multiline-js
          className={cx('flex flex-row mb24-ns', {
            'justify-start': isParts,
            'flex-row-reverse-m flex-row-reverse-l': !isParts,
          })}
        >
          {renderProposals()}
          <GridCell
            width={gridWidth}
            className={cx('pr24-m pr24-l', { w100: isParts, [styles.marginAuto]: isParts })}
          >
            <FormEditAd
              data={data}
              changeOverlay={changeOverlay}
              overlay={overlay}
              urlId={urlId}
              updateData={getData}
              isParts={isParts}
            />
          </GridCell>
        </Grid>
      </div>
      <ModalSuccessAndError
        title="Atenção"
        className="z-max"
        subTitle="Deseja realmente excluir esta proposta?"
        isOpen={openModalConfirm}
        success={false}
        actionBtn={confirmOnDelete}
        closeModal={closeModal}
        actionBtnSecondary={closeModal}
        textBtnClose="Excluir"
        textBtnSecondary="Cancelar"
      />
    </>
  );
};
