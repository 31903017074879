import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { callAll } from '../../../../utils/callAll';
import { ModalShowProposal } from '../../../modals/ShowProposal';
import { ProposalList } from '../../../modals/ShowProposal/components/ProposalList';
import { ProposalListSmall } from '../../../modals/ShowProposal/components/ProposalListSmall';
import { DataType } from '../../../modals/ShowProposal/interfaces';

interface Props {
  data: DataType[];
  handleDelete: (event: any) => void;
  toggleModal: [boolean, (open: boolean) => void];
}
export const Proposals = ({ data, handleDelete, toggleModal }: Props) => {
  const [openModal, setOpenModal] = toggleModal;
  const [proposalId, setProposalId] = useState<number>();

  const click767 = () => {
    setProposalId(-1);
    setOpenModal(!openModal);
  };
  const openProposal = (id: number) => {
    setProposalId(id);
    setOpenModal(!openModal);
  };
  const closeModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <MediaQuery maxWidth={767}>
        <ProposalListSmall data={data} onClick={click767} />
        <ModalShowProposal
          open={[openModal, setOpenModal]}
          proposalControl={[proposalId, setProposalId]}
          isMobile
          data={data}
          handleDelete={callAll(closeModal, handleDelete)}
        />
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <ProposalList data={data} setCurrent={openProposal} handleDelete={handleDelete} />
        <ModalShowProposal
          open={toggleModal}
          proposalControl={[proposalId, setProposalId]}
          isMobile={false}
          data={data}
          handleDelete={callAll(closeModal, handleDelete)}
        />
      </MediaQuery>
    </>
  );
};
